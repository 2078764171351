
/* buttons */

.MuiButton-root {
  border-radius: 8px;
  box-shadow: none;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  white-space:nowrap;
  font-size: 14px;
  font-weight: 600;
  font-family: Inter !important;
  height: 40px;
  /* letter-spacing: 1px; */
  /* text-transform: uppercase; */
}

.MuiButton-root:hover {
  box-shadow: none;
  background-color: var(--primary-hover);
}

.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  background-color: var(--background);
  color: var(--text-light);
  border: 1px solid var(--background);
}

.MuiButtonBase-root.MuiButton-text.Mui-disabled {
  background-color: var(--background);
  color: var(--text-light);
  border: 1px solid var(--text-light);
  opacity: 0.7;
}

.MuiButton-text {
  padding-left: 16px;
  padding-right: 16px;
  min-width: unset;
  background-color: var(--primary-background);
  border: 1px solid var(--primary);
  color: var(--primary);
}

.MuiButton-text:hover {
  background-color: var(--primary-background-hover);
}

.MuiChip-root {
  color: var(--text-dark);
  font-size: 13px;
  background-color: rgba(0, 0, 0, 0.06);
  font-weight: 400;
}

.MuiButtonBase-root.MuiChip-root {
  background-color: var(--primary);
  font-size: 13px;
  color: white;
  font-weight: 400;
}

.MuiButtonBase-root.MuiChip-root .MuiChip-avatar {
  color: rgba(93, 146, 224, 0.5);
}

.MuiButtonBase-root.MuiChip-root:hover {
  background-color: var(--primary-hover);
}

.MuiTouchRipple-root {
  display: none !important;
  opacity: 0 !important;
}

.MuiButtonBase-root.MuiFab-root {
  background-color: var(--primary-dark);
  color: white;
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 1000;
  box-shadow: var(--shadow);
}

@media only screen and (max-width: 768px) {
  button.MuiButtonBase-root.MuiFab-root {
    bottom: 32px;
    right: 32px;
  }
}

.MuiButtonBase-root.MuiFab-root:hover {
  background-color: var(--primary-dark-hover);
}

/* app bar */

.MuiPaper-root.MuiAppBar-root {
  background-color: var(--primary);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: none;
  height: 75px;
  overflow-x: scroll;
}

.MuiTabs-root {
  min-height: 75px;
}

.MuiButtonBase-root.MuiTab-root {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  min-height: 75px;
  color: var(--text-dark);
  margin-left: 16px;
  margin-right: 16px;
}

.MuiButtonBase-root.MuiTab-root.Mui-selected {
  color: var(--primary);
  opacity: 1;
}

.MuiTabs-indicator {
  background-color: var(--primary);
  height: 4px;
}

/* drawers */

.MuiDrawer-backdrop {
  background-color: rgba(64, 71, 80, 0.25);
}

.MuiPaper-root.MuiDrawer-paper .MuiList-root {
  padding-top: 32px;
  padding-bottom: 32px;
}

.MuiPaper-root.MuiDrawer-paper {
  width: 350px;
  height: 100vh;
  box-shadow: var(--shadow);
}

@media only screen and (max-width: 768px) {
  div.MuiPaper-root.MuiDrawer-paper {
    width: 275px;
  }
}

.MuiButtonBase-root.MuiListItem-root {
  padding: 8px 32px;
}

.MuiButtonBase-root.MuiListItem-root:hover {
  background-color: var(--background);
}

/* progress bars */

.MuiLinearProgress-root {
  background-color: var(--primary-background);
}

.MuiLinearProgress-bar {
  background-color: var(--primary);
}

.MuiCircularProgress-root {
  animation-duration: 1000ms;
}

.MuiCircularProgress-svg {
  color: var(--primary);
  stroke-linecap: round;
}

/* dialogs */

.MuiDialog-paper {
  border-radius: 8px;
  box-shadow: var(--shadow);
  max-width: 60vw;
}

.MuiModal-root {
  z-index: 2500;
}

@media only screen and (max-width: 768px) {
  div.MuiDialog-paper {
    min-width: 90vw !important;
    max-height: 85vh !important;
  }
}

@media only screen and (max-width: 768px) {
  div.MuiDialog-paper {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.MuiDialogActions-root {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  min-height: 75px;
  border-top: solid 1px var(--line-light);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.MuiTypography-root.MuiDialogTitle-root {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--primary);
  font-size: 18pt;
  font-weight: 600;
  line-height: 1;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 75px !important;
  height: unset;
  border-bottom: solid 1px var(--line-light);
}

.MuiDialogContent-root {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 40px !important;
  padding-bottom: 40px;
}

@media only screen and (max-width: 768px) {
  div.MuiDialogContent-root {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px !important;
    padding-bottom: 16px;
  }
}

@media only screen and (max-width: 768px) {
  h2.MuiTypography-root.MuiDialogTitle-root {
    padding-left: 16px;
    padding-right: 16px;
  }
}

/* cards */

.MuiCard-root {
  border-radius: 0px;
}

@media only screen and (max-width: 768px) {
  div.MuiCard-root {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.MuiCard-root:hover {
  box-shadow: var(--shadow-hover);
}

.MuiPaper-root.MuiCard-root {
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-radius: 8px;
  -webkit-border-radius: 8px;
}

.MuiCardContent-root {
  background-color: white;
  position: relative;
  z-index: 100;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
}

@media only screen and (max-width: 768px) {
  div.MuiCardContent-root {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.MuiCardMedia-root {
  transition-duration: 0.3s;
  background-color: var(--primary);
}

.MuiCard-root:hover .MuiCardMedia-root {
  transform: scale(1.05);
}

.MuiCardActionArea-root:hover {
  position: relative;
  z-index: 150;
}

/* pagination */

.MuiPaginationItem-root {
  color: var(--text-dark);
}

.MuiButtonBase-root.MuiPaginationItem-root {
  color: var(--text-dark);
  font-weight: 300;
  margin-left: 6px;
  margin-right: 6px;
}

.MuiButtonBase-root.MuiPaginationItem-root:hover {
  background-color: var(-background-transparent);
}

.MuiButtonBase-root.MuiPaginationItem-root.Mui-disabled {
  opacity: 0.3;
}

.MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
  background-color: var(--primary);
  color: white;
}

.MuiSvgIcon-root.MuiPaginationItem-icon {
  opacity: 0.4;
}

/* accordions */

.MuiPaper-root.MuiAccordion-root {
  box-shadow: none;
  background-color: unset;
}

.MuiPaper-root.MuiAccordion-root.Mui-expanded {
  margin: 0px;
}

.MuiButtonBase-root.MuiAccordionSummary-root {
  min-height: unset;
}

.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
  min-height: unset;
}

.MuiPaper-root.MuiAccordion-root::before {
  height: 0px;
  top: 0;
}

.MuiButtonBase-root.MuiAccordionSummary-root {
  padding: 0px;
  flex-direction: column;
  align-items: flex-start;
}

.MuiAccordionSummary-content {
  margin: 0px;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0px;
}

.MuiAccordionSummary-expandIconWrapper {
  padding-left: 16px;
  padding-right: 16px;
}

.MuiAccordionDetails-root {
  padding: 0px;
}

/* tables */

.MuiTableCell-root {
  max-width: 250px;
  color: var(--text-dark);
  font-weight: 400;
  line-height: 1;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

.MuiTableRow-root:hover {
  background-color: rgba(241, 242, 246, 0.5);
}

.MuiStepLabel-label {
  font-size: 12pt;
  font-weight: 400 !important;
}

.MuiStepLabel-label.Mui-active {
  color: var(--primary);
  font-weight: 600 !important;
}

.MuiStepLabel-label.Mui-completed {
  color: var(--text-light);
}

.MuiStepLabel-label.Mui-disabled {
  color: var(--text-light);
}

.MuiStepLabel-vertical {
  padding-top: 10px;
  padding-bottom: 10px;
}

.MuiStepLabel-iconContainer {
  padding-right: 32px;
}

@media only screen and (max-width: 768px) {
  .MuiStepLabel-iconContainer {
    padding-right: 16px;
  }
}

.MuiStepIcon-root {
  color: var(--line-dark);
}

.MuiStepIcon-root.Mui-completed {
  color: var(--line-dark);
}

.MuiStepIcon-root.Mui-active {
  color: var(--primary);
}

.MuiStepContent-root {
  padding-left: 42px;
  border-color: var(--line-light);
  border-width: 1px;
}

@media only screen and (max-width: 768px) {
  .MuiStepContent-root {
    padding-left: 28px;
  }
}

.MuiStepConnector-line {
  min-height: 32px;
  border-color: var(--line-light);
  border-width: 1px;
}

/* text area and select */

.MuiFormLabel-root.MuiInputLabel-root {
  font-size: 15px;
  color: var(--text-medium);
  font-weight: 300;
  transform: translate(22px, 23px) scale(1);
}

.MuiFormLabel-root.MuiInputLabel-shrink {
  color: var(--primary);
  transform: translate(24px, 11px) scale(0.8);
}

.MuiInputBase-input.MuiFilledInput-input {
  color: var(--text-dark);
  font-weight: 300;
  padding-top: 22px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.MuiInputBase-root.MuiFilledInput-root {
  background-color: var(--background);
  border: 1px solid var(--background);
  font-weight: 300;
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 22px;
  padding-right: 22px;
  transition: border-color 0.3s;
}

.MuiInputBase-root.MuiFilledInput-root.Mui-focused {
  border: 1px solid var(--primary);
}

.MuiInputBase-root.MuiFilledInput-root:before {
  border-bottom: none !important;
}

.MuiInputBase-root.MuiFilledInput-root:after {
  border-bottom: none !important;
}

.MuiInputBase-root.MuiFilledInput-root:before:hover {
  border-bottom: none;
}

.MuiFormHelperText-root {
  font-weight: 300;
  font-size: 13px;
  color: var(--text-light);
  margin-top: 6px;
  margin-left: 22px;
  margin-right: 22px;
}

.MuiInputAdornment-root {
  padding-right: 8px;
}

.MuiButtonBase-root.MuiIconButton-root {
  color: var(--primary);
}

.MuiButtonBase-root.MuiIconButton-root:hover {
  background-color: var(--background-hover);
}


.MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.MuiSelect-select {
  background-color: var(--background);
}

.MuiSelect-icon {
  color: var(--text-light);
  padding-right: 8px;
  padding-left: 8px;
  transition: transform 0.3s;
}

/* popover */

.MuiPopover-paper {
  box-shadow: var(--shadow);
  padding-top: 12px;
  padding-bottom: 12px;
}

.MuiMenu-list {
  padding-top: 0px;
  padding-bottom: 0px;
}

.MuiMenuItem-root {
  background-color: white  !important;
  font-size: 15px;
  color: var(--text-dark);
  font-weight: 300;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  padding-right: 24px;
}

.MuiMenuItem-root:hover {
  background-color: var(--background) !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: var(--primary) !important;
  color: white;
}

.MuiMenuItem-root.Mui-selected:hover {
  background-color: var(--primary-hover) !important;
  color: white;
}

.MuiPopover-root {
  z-index: 6100;
}

.MuiPopover-paper {
  margin-top: 16px;
  margin-left: 16px;
  border-radius: 8px;
  max-height: 80vh;
  margin-top: 16px;
  margin-bottom: 16px;
}

/* checkbox */

.MuiFormControlLabel-root .MuiFormControlLabel-label {
  padding-left: 16px;
  font-weight: 400;
  font-size: 10pt;
  font-family: "Roboto Mono";
  padding-top: 0px;
  padding-bottom: 0px;
  color: var(--text-medium);
}

.MuiButtonBase-root.MuiCheckbox-root {
    color: var(--line-light);
}

.MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
    color: var(--primary);
}

.MuiButtonBase-root.MuiCheckbox-root {
    color: var(--line-dark) !important;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    padding-right: 0px;
    /* background-color: unset; */
}

.MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
    color: var(--primary) !important;
}
