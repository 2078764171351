html, body {
  margin: 0;
  padding: 0;
}

* {
  font-family: "Inter", sans-serif;
}

:root {
  --background: #F1F2F6;
  --white-hover: #F9F9FA;
  --line-light: #E6E8EC;
  --line-dark: #CED0D4;
  --text-light: #8D97A4;
  --text-medium: #5D6977;
  --text-dark: #404750;
  --text-code: #355E95;
  --primary: #5D92E0;
  --primary-hover: #4E80CD;
  --primary-dark: #294873;
  --primary-dark-hover: #1E3453;
  --primary-background: #DEE8F5;
  --primary-background-hover: #C7D7EE;
  --background-code: rgba(93, 146, 224, 0.1);
  --line-code: rgba(93, 146, 224, 0.2);
  --shadow: 0px 0px 15px 0px rgba(64, 71, 80, 0.3);
  --shadow-hover: 0px 0px 25px 0px rgba(64, 71, 80, 0.4);
  --background-transparent: #F4F6F9;
  --primary-gradient: linear-gradient(180deg, rgba(93, 146, 224, 1) 0%, rgba(93, 146, 224, 0.2) 30%, rgba(93, 146, 224, 0.8) 100%);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.title-small {
  color: var(--primary);
  font-size: 18pt;
  font-weight: 700;
  padding-top: 32px;
  padding-bottom: 8px;
  line-height: 1.5;
}

.title-medium {
  color: var(--primary);
  font-size: 18pt;
  font-weight: 700;
  padding-top: 32px;
  padding-bottom: 10px;
  line-height: 1.5;
}

@media only screen and (max-width: 768px) {
  div.title-medium {
    font-size: 18pt;
  }
}

.title-large {
  color: var(--primary);
  font-size: 36pt;
  font-weight: 800;
  margin-top: 32px;
  margin-bottom: 32px;
  line-height: 1.3;
  text-align: center;
  width: 100%;
  &::before {
    content: '';
    display: block;
    margin-top: -12px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -12px;
  }
}

@media only screen and (max-width: 768px) {
  div.title-large {
    font-size: 20pt;
  }
}

.sub-title {
  color: var(--text-light);
  font-size: 14pt;
  font-weight: 600;
  padding-top: 0px;
  padding-bottom: 10px;
}

.text {
  color: var(--text-dark);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.6;
  margin-top: 16px;
  margin-bottom: 16px;
  &::before {
    content: '';
    display: block;
    margin-top: -6px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -6px;
  }
}

.bold {
  font-weight: 600;
}

.italic {
  font-weight: 300;
  font-style: italic;
}

.link {
  color: var(--primary);
  cursor: pointer;
  font-weight: 300;
}

.link-white {
  color: white;
  cursor: pointer;
  font-weight: 600;
}

.white {
  color: white;
}

.bullet-point-container {
  margin: 0px;
  padding-left: 38px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.bullet-point {
  margin: 0px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: var(--text-dark);
  font-size: 11.5pt;
  font-weight: 300;
  line-height: 1.8;
}

.indent {
  padding-left: 32px;
}

.beta {
  color: var(--primary);
  font-size: 10pt;
  font-weight: 400;
}

.code {
  font-family: "Roboto Mono";
  background-color: var(--background-code);
  border: 1px solid var(--line-code);
  color: var(--text-code);
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 4px;
  padding-right: 4px;
  /* white-space: nowrap; */
}

.code-block {
  font-family: "Roboto Mono";
  background-color: var(--background-code);
  border: 1px solid var(--line-code);
  color: var(--text-code);
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 16px;
  margin-right: 16px;
  overflow-x: scroll;
  max-width: calc(100% - 32px - 32px);
  line-height: 1.8;
}

.quote {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.8;
  color: var(--text-light);
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-left: 6px solid var(--line-light);
  margin-left: 16px;
}

.faq-container {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
}

.faq-question-container {
  display: flex;
  font-size: 14pt;
  font-weight: 500;
  color: var(--primary);
  padding-top: 16px;
  padding-bottom: 16px;
  line-height: 1.6;
}

.faq-answer-container {
  display: flex;
  padding-top: 16px;
  padding-bottom: 0px;
}

.faq-expand-icon {
  line-height: 1;
  padding: 0px;
  margin: 0px;
  margin-top: -8px;
  margin-bottom: -5px;
  margin-left: 8px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: var(--text-light);
}

.dialog-backdrop {
  background-color: rgba(64, 71, 80, 0.25) !important;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
