
.home-page-title-container {
  position: absolute;
  z-index: 100;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: var(--primary-gradient);
}

.home-page-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 150px;
  font-size: 160px;
  font-weight: 800;
  color: white;
  letter-spacing: 20px;
  line-height: 1;
}

@media only screen and (max-width: 768px) {
  div.home-page-title {
    font-size: 50px;
  }
}

.home-page-caption-container {
  position: absolute;
  z-index: 200;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.home-page-caption {
  font-size: 24px;
  font-weight: 300;
  color: white;
  line-height: 1.7;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 4px;
}

@media only screen and (max-width: 768px) {
  div.home-page-caption {
    font-size: 16px;
  }
}

.home-page-arrow-container {
  position: absolute;
  z-index: 200;
  height: 150px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-page-arrow {
  color: white;
  font-size: 50px;
  animation: bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

@media only screen and (max-width: 768px) {
  img.home-page-arrow {
    display: none;
  }
}

@keyframes bounce {
  50% {
    transform: translateY(-10px);
  }
}

.app-bar {
  position: relative;
  z-index: 1200;
  min-height: 80px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary);
  /* border-bottom: 8px solid var(--line-light); */
}

/* @media only screen and (max-width: 768px) {
  div.app-bar {
    flex-direction: column;
    align-items: flex-start;
  }
} */

.app-bar-banner {
  position: relative;
  z-index: 1000;
  min-height: 80px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary);
}

@media only screen and (max-width: 768px) {
  div.app-bar-banner {
    flex-direction: column;
    align-items: flex-start;
  }
}

.tab {
  font-size: 16px;
  font-weight: 400;
  /* letter-spacing: 0.5px; */
  color: white;
  padding-left: 24px;
  padding-right: 24px;
  text-decoration: none;
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  a.tab {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.tab:hover {
  opacity: 0.7;
  /* text-decoration: underline; */
}

.authentication-container {
  padding-right: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap;
}

@media only screen and (max-width: 768px) {
  div.authentication-container {
    padding-right: 16px;
  }
}

.username-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap;
  color: white;
}

.username {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 16px;
  padding-left: 16px;
  border-right: 1px solid white;
  color: white;
  font-size: 10pt;
  font-weight: 500;
}

.authentication-button {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  cursor: pointer;
  color: white;
  font-size: 10pt;
  font-weight: 300;
  letter-spacing: 0.5px;
  text-decoration: none;
  opacity: 1;
}

.authentication-button:hover {
  opacity: 0.7;
}

.branding-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding-left: 50px;
}

@media only screen and (max-width: 768px) {
  div.branding-container {
    padding-left: 16px;
  }
}

.app-branding {
  font-size: 16px;
  font-weight: 300;
  color: var(--primary);
  text-decoration: none;
}

.app-branding-large {
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 2px;
  color: var(--primary);
  margin-left: 2px;
  margin-right: 0px;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  div.tab-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    overflow-x: scroll;
  }
}

.form-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.form-button-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media only screen and (max-width: 768px) {
  div.form-button-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.form-container {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 24px;
}

.form-container-centered {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.form-grid-left {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.form-grid-centered {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.missing-value {
  color: var(--text-light);
}

.description-container {
  padding-top: 8px;
  padding-bottom: 8px;
}

.code-label {
  padding-top: 10px;
  padding-bottom: 10px;
}

.code-small {
  background-color: var(--background);
  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  color: var(--text-dark);
  font-size: 13px;
  font-weight: 500;
}

.row-left {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media only screen and (max-width: 768px) {
  div.row-left {
    flex-direction: column;
  }
}

.row-right {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.row-centered {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.block-color-title {
  font-size: 42px;
  line-height: 1.5;
  padding-top: 0px;
  padding-bottom: 16px;
  font-weight: 600;
  color: white;
}

.block-centered {
  justify-content: center !important;
  align-items: center !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 20%;
  padding-right: 20%;
  background-color: white;
}

@media only screen and (max-width: 768px) {
  div.block {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.block-shaded {
  background-color: var(--background);
}

.block-dark {
  background-color: var(--primary);
}

.block-color {
  background-color: var(--primary);
}

.text-white {
  color: white !important;
}

.text-card {
  font-size: 15px;
  padding-top: 10px !important;
  padding-bottom: 0px !important;
}

.add-icon {
  margin-top: 8px;
  font-size: 16px;
  color: var(--primary);
  cursor: pointer;
}

.add-icon:hover {
  color: var(--primary);
}

.card-container {
  border-radius: 16px;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-left: 32px;
  margin-right: 32px;
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  box-shadow: var(--shadow);
  transition: 0.3s ease-in-out;
}

.card-container:hover {
  box-shadow: var(--shadow-hover);
}

@media only screen and (max-width: 768px) {
  div.card-container {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.card-grid-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
}

.card-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 828px;
}


.card-spacer {
  width: calc(350px + 32px + 32px);
}

.card-title {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.3;
  color: var(--primary);
}

.card-text {
  font-weight: 300;
  font-size: 15px;
  line-height: 1.6;
  padding-top: 16px;
  color: var(--text-dark);
}

.page-centered {
  position: relative;
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-container {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-right: 32px;
}

.button-container-right {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 32px;
}

.button-container-centered {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
}

.button-white {
  background-color: white !important;
  color: var(--primary);
}

.button-white:hover {
  background-color: white !important;
  color: var(--primary);
  opacity: 0.9;
}

.sidebar-section-heading {
  font-weight: 300;
}

.sidebar-section-text {
  color: var(--primary) !important;
  font-weight: 500;
  /* text-transform: uppercase; */
  font-size: 16px;
  /* letter-spacing: 1px; */
}

.sidebar-subsection-text {
  color: var(--text-dark) !important;
  font-weight: 300;
  font-size: 15px;
  padding-left: 16px;
}

.message {
  padding: 16px 24px;
  border: 2px solid var(--primary);
  background-color: var(--primary-background);
  color: var(--primary);
  border-radius: 8px;
  font-weight: 400;
}

.data-table-container {
  border: 1px solid var(--line);
}

.data-table {
  max-height: 500px;
}

/* tool classes */

.tool-app-bar-no-shadow {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 80px;
  background-color: white;
  z-index: 2100;
  border-bottom: 1px solid var(--line-light);
}

@media only screen and (max-width: 768px) {
  div.tool-app-bar-no-shadow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 32px;
  }
}

.tool-app-bar {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 80px;
  background-color: white;
  z-index: 2100;
  border-bottom: 1px solid var(--line-light);
  /* box-shadow: var(--shadow); */
}

@media only screen and (max-width: 768px) {
  div.tool-app-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 32px;
  }
}

/* .tool-branding {
  color: var(--primary);
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
}

.tool-branding-large {
  color: var(--primary);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 2px;
} */

.tool-back-button-container {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  height: 80px;
  padding-left: 32px;
}

@media only screen and (max-width: 768px) {
  div.tool-back-button-container {
    padding-left: 16px;
  }
}

.tool-username-container {
  position: absolute;
  top: 0px;
  right: 50px;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.tool-username {
  color: var(--primary);
  font-size: 14px;
  text-decoration: none;
}

.tool-username-icon {
  padding-right: 12px;
  color: var(--primary);
}

.tool-message-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 10%;
  padding-right: 10%;
}

@media only screen and (max-width: 768px) {
  div.tool-message-container {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.tool-message-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 24px;
  margin-top: -2px;
}

.tool-message-icon {
  font-size: 28pt;
}

.tool-message-text {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.6;
}

.tool-message-box {
  border-radius: 8px;
  color: rgba(93, 146, 224, 1);
  background-color: rgba(93, 146, 224, 0.1);
  border-style: solid;
  border-width: 1px;
  border-color: rgba(93, 146, 224, 0.5);
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* codebook */

.codebook-container {
  display: flex;
  flex-direction: column;
}

.codebook-content-container {
  position: absolute;
  top: 80px;
  bottom: 80px;
  left: 0px;
  right: 0px;
  overflow-y: scroll;
}

.codebook-header-container {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 80px;
  background-color: white;
  border-bottom: 1px solid var(--line-light);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  font-size: 18pt;
  font-weight: 600;
}

.codebook-footer-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 80px;
  padding-right: 32px;
  background-color: white;
  border-top: 1px solid var(--line-light);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.codebook-dataset-container {
  padding-bottom: 22px;
}

.codebook-divider {
  border-bottom: 1px solid var(--line-light);
}

.codebook-dataset-label {
  padding-top: 8px;
  padding-bottom: 22px;
  font-weight: 600;
  color: var(--primary);
  font-size: 18pt;
}

.codebook-dataset-name {
  font-family: "Roboto Mono";
  background-color: var(--background-code);
  border: 1px solid var(--line-code);
  color: var(--text-code);
  font-size: 10pt;
  font-weight: 400;
  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  white-space: nowrap;
}

.codebook-dataset-description {
  padding-top: 22px;
  font-weight: 300;
  font-size: 12pt;
  color: var(--text-dark);
  line-height: 1.8;
}

.codebook-variables-container {
  padding-top: 16px;
}

.codebook-variable-container {
  padding-top: 16px;
  padding-bottom: 16px;
}

.codebook-variable-description {
  padding-top: 4px;
  padding-left: 32px;
}

.codebook-variable-header {
  padding-bottom: 16px;
}

.codebook-variable-body {
  /* border-left: 6px solid white; */
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 1.8;
}

/* .codebook-variable-body:hover {
  border-left: 6px solid var(--primary);
} */

.codebook-variable-name {
  font-family: "Roboto Mono";
  background-color: var(--background-code);
  border: 1px solid var(--line-code);
  color: var(--text-code);
  font-size: 10pt;
  font-weight: 400;
  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  white-space: nowrap;
  margin-right: 16px;
}

.codebook-variable-type {
  font-family: "Roboto Mono";
  background-color: var(--background-code);
  border: 1px solid var(--line-code);
  color: var(--text-code);
  font-size: 10pt;
  font-weight: 400;
  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  white-space: nowrap;
  margin-right: 16px;
}

.codebook-variable-description {
  font-weight: 300;
  font-size: 12pt;
  color: var(--text-dark);
  line-height: 1.5;
}

/* parallax classes */

.parallax-background {
  background-color: var(--primary);
}

.parallax-image {
  opacity: 1;
  -webkit-animation: fadein 2s;
  animation: fadein 2s;
  object-fit: cover;
  width: 110vw;
  height: 100vh;
}

.parallax-image-hidden {
  opacity: 0;
}

.parallax-content {
  position: absolute;
  z-index: 200;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-image: var(--primary-gradient);
}

.parallax-title {
  margin-bottom: 16px;
  font-size: 72px;
  font-weight: 800;
  color: white;
  letter-spacing: 2px;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  div.parallax-title {
    font-size: 32px;
  }
}

.parallax-caption {
  position: absolute;
  z-index: 300;
  right: 16px;
  bottom: 16px;
  color: var(--background);
  opacity: 0.4;
  line-height: 1;
  font-weight: 300;
  font-size: 10pt;
}

/* bio classes */

.bio-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  div.bio-container {
    flex-direction: column;
    align-items: center;
  }
}

.bio-photo-container {
  clip-path: circle(90px at center);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  div.bio-photo-container {
    margin-bottom: 32px;
  }
}

.bio-photo {
  width: 180px;
  opacity: 0.9;
  filter: grayscale(100%);
}

.bio-photo-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  opacity: 0;
}

.bio-body {
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 32px;
  max-width: 600px;
}

@media only screen and (max-width: 768px) {
  div.bio-body {
    margin-left: 0px;
  }
}

.bio-name {
  color: var(--primary);
  font-weight: 700;
  font-size: 24px;
  padding-bottom: 4px;
}

.bio-text {
  color: var(--text-dark);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.6;
  margin-top: 16px;
  margin-bottom: 16px;
  &::before {
    content: '';
    display: block;
    margin-top: -6px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -6px;
  }
}

.bio-institution {
  color: var(--text-light);
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 8px;
  line-height: 1.8;
}

.bio-preview {
  font-size: 15px;
  padding-bottom: 14px !important;
}

.bio-details {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 10px;
  margin-bottom: 16px;
  border-left: 6px solid var(--line-light);
  margin-left: 16px;
}

.bio-expand-icon {
  width: 24px;
  height: 24px;
  line-height: 1;
  padding: 0px;
  margin: 0px;
  margin-top: -8px;
  margin-bottom: -6px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: var(--text-light);
}

.bio-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.bio-title {
  color: var(--primary);
  font-weight: 600;
  font-size: 18px;
  padding-top: 10px;
  /* padding-bottom: 4px; */
}

.bio-icon {
  color: var(--primary) !important;
  margin-right: 16px;
}

.bio-icon:hover {
  color: var(--primary-hover) !important;
}

.bio-photo-placeholder {
  background: var(--background);
  border-radius: 50%;
  width: 180px;
  height: 180px;
}

/* text corpus classes */

.text-corpus-results-container {
  /* padding-top: 100px; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.text-corpus-result-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 8px;
}

@media only screen and (max-width: 768px) {
  div.text-corpus-result-container {
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: column;
  }
}

.text-corpus-paragraph-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 8px;
  padding-top: 12px;
  padding-right: 16px;
  padding-left: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.text-corpus-paragraph-container:hover {
  background-color: var(--background);
}

.text-corpus-document-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 8px;
  padding-top: 12px;
  padding-right: 16px;
  padding-left: 16px;
  border-radius: 8px;
}

.text-corpus-paragraph-container-indented {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 48px;
  padding-left: 16px;
  border-left: 6px solid var(--background);
}

.text-corpus-paragraph-number-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  min-width: 50px;
  font-size: 32px;
  font-weight: 600;
  color: var(--primary);
  padding-top: 6px;
  margin-right: 16px;
}

@media only screen and (max-width: 768px) {
  div.text-corpus-paragraph-number-container {
    font-size: 24px;
    margin-left: 16px;
    margin-bottom: 8px;
    margin-top: 16px;
    align-items: flex-start;
  }
}

.text-corpus-loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1010;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.text-corpus-gap {
  height: 8px;
}

.text-corpus-divider-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
}

.text-corpus-divider {
  width: 100%;
  border-top: 1px solid var(--line);
}

.text-corpus-divider-label {
  padding-left: 8px;
  padding-right: 8px;
  font-size: 13px;
  color: var(--text-light);
  font-weight: 400;
}

.text-corpus-navigation-container-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.text-corpus-navigation-container-bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 34px;
  padding-bottom: 50px;
}

.text-corpus-document-title-container {
  padding-bottom: 8px;
  padding-top: 12px;
  padding-right: 16px;
  padding-left: 16px;
}

.text-corpus-document-title {
  color: var(--primary);
  font-size: 42px;
  line-height: 1;
  font-weight: 600;
  padding-top: 4px;
  padding-bottom: 16px;
  overflow-wrap: break-word;
}

@media only screen and (max-width: 768px) {
  div.text-corpus-document-title {
    font-size: 32px;
  }
}

.text-corpus-document-heading {
  color: var(--primary);
  font-size: 24px;
  line-height: 1;
  font-weight: 600;
  padding-top: 4px;
  padding-bottom: 4px;
}

.text-corpus-text {
  width: 100%;
  color: var(--text-dark);
  font-size: 15px;
  line-height: 1.8;
  font-weight: 300;
}

.text-corpus-citation {
  font-size: 14px;
  line-height: 1.4;
  color: var(--text-light);
  font-weight: 500;
  padding-top: 12px;
  padding-bottom: 8px;
}

.text-corpus-tag-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: 4px;
}

.text-corpus-tag-row-hidden {
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.text-corpus-tag-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.text-corpus-document-tag {
  color: var(--primary) !important;
  background-color: #DFEAF9 !important;
}

.text-corpus-document-tag:hover {
  background-color: #CEDEF6 !important;
}

.text-corpus-add-tag-button {
  color: var(--primary);
  opacity: 0.7;
  cursor: pointer;
}

.text-corpus-add-tag-button:hover {
  opacity: 1;
}

/* text editor classes */

.text-editor-app-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 80px;
  background-color: white;
  border-bottom: 1px solid var(--line);
  z-index: 2100;
}

.text-editor-branding {
  color: var(--primary);
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
}

.text-editor-branding-large {
  color: var(--primary);
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 2px;
}

.text-editor-nav-buttons {
  display: flex;
  justify-content: flex-start;
}

.text-editor-action-buttons {
  /* width: 100%; */
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: flex-end;
}

.text-editor-username-container {
  position: absolute;
  top: 0px;
  right: 50px;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.text-editor-username {
  color: var(--primary);
  font-size: 14px;
  text-decoration: none;
}

.text-editor-username-icon {
  padding-right: 12px;
  color: var(--primary);
}

.text-editor-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 80px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 2000;
  background-color: white;
}

.text-editor-search-panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 80px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow-y: scroll;
}

.text-editor-search-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.text-editor-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 50px;
  color: var(--primary);
  font-weight: 600;
  padding-bottom: 100px;
  background-color: var(--background);
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
}

.text-editor-top-bar {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid var(--line);
  padding-left: 50px;
  padding-right: 50px;
  overflow-x: scroll;
  /* box-shadow: var(--shadow); */
}

.text-editor-bottom-bar {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-top: 1px solid var(--line);
  overflow-x: scroll;
  padding-left: 50px;
  padding-right: 50px;
  /* box-shadow: var(--shadow); */
}

.text-editor-no-results-container {
  position: absolute;
  top: 80px;
  bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-editor-no-results-text {
  color: var(--primary);
  font-weight: 500;
  font-size: 15px;
  padding-bottom: 32px;
}

.text-editor-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.text-editor-button-container-left {
  padding-right: 16px;
}

.text-editor-button-container-right {
  padding-left: 16px;
}

.text-editor-chip-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.MuiChip-root.text-editor-chip {
  margin-right: 8px;
  color: var(--text-dark);
}

.MuiChip-root.text-editor-chip-color {
  margin-right: 8px;
  color: white;
  background-color: var(--primary);
}

.text-editor-results {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.text-editor-results-hidden {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.text-editor-image-panel {
  position: absolute;
  left: 0px;
  right: 50%;
  top: 80px;
  bottom: 80px;
}

.text-editor-image-container {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  overflow-y: scroll;
  border: 1px solid var(--line);
  border-radius: 8px;
  border: none;
  scrollbar-width: none;
}

.text-editor-image-container::-webkit-scrollbar {
  display: none;
}

/* .text-editor-image-container::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: var(--line);
  border: 6px solid white;
  border-radius: 10px;
} */

.text-editor-image {
  width: 100%;
  /* margin-top: -8px;
  margin-bottom: -8px; */
  /* padding-top: 50px;
  padding-bottom: 50px; */
}

.text-editor-input-panel {
  position: absolute;
  left: 50%;
  right: 0px;
  top: 80px;
  bottom: 80px;
  border-left: 1px solid var(--line);
}

.text-editor-input-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 80px;
  right: 80px;
  border: 1px solid var(--line);
  border-radius: 8px;
  border: none;
  /* box-shadow: var(--shadow); */
  /* border: none; */
  /* border-top: 1px solid var(--line);
  border-bottom: 1px solid var(--line);
  border-radius: 0px; */
}

.text-editor-input {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: calc(100% - 150px);
  outline: none;
  resize: none;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 0px;
  padding-right: 0px;
  color: var(--text-dark);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  border: none;
  border-radius: 8px;
  scrollbar-width: none;
}

.text-editor-input::-webkit-scrollbar {
  display: none;
}

/* .text-editor-input::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: var(--line);
  border: 6px solid white;
  border-radius: 10px;
} */

.text-editor-input::selection {
  background: var(--primary);
  color: white;
}

.text-editor-progress-bar-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-editor-circle-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-editor-background-circle .MuiCircularProgress-svg {
  color: var(--primary-background);
}

/* dialog */

.MuiDialog-paper.download-tool-codebook-dialog-paper {
  border-radius: 8px;
  max-width: 70vw;
  max-height: 85vh;
}

.MuiDialog-paper.download-tool-preview-dialog-paper {
  border-radius: 8px;
  max-width: 85vw;
  max-height: 85vh;
}

.MuiDialog-paper.search-tool-judge-dialog-paper {
  border-radius: 8px;
  max-width: 80vw;
  min-width: 50vw;
  max-height: 80vh;
}

.MuiDialog-paper.search-tool-citation-dialog-paper {
  border-radius: 8px;
  max-width: 60vw;
  min-width: 50vw;
  max-height: 80vh;
}

.MuiDialog-paper.search-tool-info-dialog-paper {
  border-radius: 8px;
  max-width: 60vw;
  min-width: 40vw;
  max-height: 80vh;
}

/* stepper */

.download-tool-dialog {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 60px;
  padding-right: 60px;
}

.download-tool-preview-container {
  height: 100%;
  overflow-y: scroll;
  border: solid  1px var(--line-light);
  margin-top: 32px;
}

.download-tool-page-container {
  padding-top: 80px;
}

.download-tool-stepper-container {
  background-color: white;
  padding-top: 24px;
  /* padding-bottom: 300px; */
}

@media only screen and (max-width: 768px) {
  div.download-tool-container {
    display: none;
  }
}

.download-tool-progress-bar-container {
  width: calc(100% - 16px);
  padding-top: 16px;
  padding-bottom: 12px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.download-tool-progress-bar-container-hidden {
  width: calc(100% - 16px);
  padding-top: 16px;
  padding-bottom: 12px;
  opacity: 0;
  cursor: default;
  transition: opacity 0.3s ease-in-out;
}

.download-tool-progress-bar {
  padding-top: 16px;
  padding-bottom: 12px;
}

.download-tool-progress-bar-caption {
  color: var(--text-medium);
  font-size: 10.5pt;
  font-weight: 300;
}


.checkbox-grid-container {
  padding-left: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  -webkit-column-count: 2;
  column-count: 2;
}


.checkbox-container {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .checkbox-grid-container {
    display: flex;
    -webkit-column-count: 1;
    column-count: 1;
    flex-direction: column;
  }
}

/* table */

.MuiTableCell-root.header-cell {
  font-family: Roboto Mono;
  font-size: 10pt;
  color: var(--text-dark);
  font-weight: 400;
  border-bottom: solid 1px var(--line-light);
  background-color: var(--background);
}

.MuiTableCell-root.body-cell {
  /* font-family: Roboto Mono; */
  font-size: 10pt;
  color: var(--text-medium);
  font-weight: 300;
  border-bottom: solid 1px var(--line-light);
}
