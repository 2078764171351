.footer {
  position: relative;
  background-color: var(--primary-dark);
  width: 100%;
  z-index: 1000;
}

.footer-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

@media only screen and (max-width: 768px) {
  div.footer-top {
    align-items: flex-start;
    padding-left: 16px;
  }
}

.footer-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

@media only screen and (max-width: 768px) {
  div.footer-middle {
    align-items: flex-start;
    padding-left: 16px;
  }
}

.footer-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  div.footer-bottom {
    align-items: flex-start;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.footer-credit {
  color: white;
  font-size: 14px;
  font-weight: 300;
  opacity: 0.4;
}

.footer-branding {
  color: #FFFFFF;
}

.footer-columns {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}

@media only screen and (max-width: 768px) {
  div.footer-columns {
    flex-direction: column;
  }
}

.footer-column {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 50px;
  padding-right: 50px;
}

@media only screen and (max-width: 768px) {
  div.footer-column {
    padding-left: 0px;
  }
}

.footer-item-container {
  margin-top: 12px;
  margin-bottom: 12px;
}

.footer-heading {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
}

.footer-heading:hover {
  text-decoration: underline;
}

.footer-item {
  color: white;
  font-size: 14px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  opacity: 0.8;
  text-decoration: none;
}

.footer-item:hover {
  text-decoration: underline;
}

.footer-break {
  height: 23px;
}
