
.search-tool-page-container {
  padding-top: 150px;
}

.search-form-panel {
  position: fixed;
  background-color: white;
  top: 80px;
  left: 0px;
  right: 0px;
  height: 80px;
  z-index: 2050;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: white;
  border-bottom: 1px solid var(--line-light);
}

@media only screen and (max-width: 768px) {
  div.search-form-panel {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.search-field-container {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 8px;
  padding-right: 8px;
}

.search-tool-search-box {
  height: 44px;
  border: unset;
  border-radius: 8px;
  background-color: var(--background);
  border: 2px solid var(--background);
  transition: border;
  transition: 0.3s ease-in-out;
  padding-left: 18px;
  font-weight: 300;
  font-size: 11pt;
  color: var(--text-dark);
}

.search-tool-search-box:focus {
  outline: none;
  border: 2px solid var(--primary);
}

.search-tool-no-results-container {
  min-height: calc(100vh - 150px);
}

input {
  padding: 0px;
  margin: 0px;
}

::placeholder {
  color: var(--text-light);
}

.search-tool-search-button {
  height: 48px;
  width: 130px;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid var(--primary);
}

.search-tool-welcome-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100vh - 150px);
  background-color: white;
  z-index: 2000;
}

.search-tool-loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 80px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  z-index: 2000;
}

.search-tool-no-results-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 80px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  z-index: 2000;
}

.search-tool-divider {
  border-bottom: solid 1px var(--line-light);
  width: 100%;
  margin-top: 46px;
  margin-bottom: 12px;
}

.search-tool-info-icon {
  color: var(--primary);
  font-size: 14pt;
  margin-bottom: -3px;
  padding-top: 0px;
  padding-left: 12px;
  padding-right: 0px;
  opacity: 0.5;
  cursor: pointer;
}

.search-tool-info-icon:hover {
  opacity: 0.8;
}

.search-tool-form-info-icon {
  color: var(--primary);
  font-size: 14pt;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 4px;
  padding-right: 16px;
  cursor: pointer;
  opacity: 0.5;
}

.search-tool-judge-icon {
  color: var(--primary);
  font-size: 100pt;
  padding-bottom: 28px;
}

.search-tool-judge-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-tool-form-info-icon:hover {
  opacity: 0.8;
}

.search-tool-member-state-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
}

.search-tool-appointment-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.search-tool-appointment-dates-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--text-light);
  font-size: 9pt;
  padding-top: 2px;
  padding-bottom: 20px;
  line-height: 1.5;
}

.search-tool-citations-dialog {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 60px;
  padding-right: 60px;
  overflow-y: scroll;
}

@media only screen and (max-width: 768px) {
  div.search-tool-citations-dialog {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.search-tool-dialog-title {
  color: var(--primary);
  font-size: 24pt;
}

.search-tool-info-dialog {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding-top: calc(50px - 32px);
  padding-bottom: calc(50px - 10px);
  padding-left: 50px;
  padding-right: 50px;
}

@media only screen and (max-width: 768px) {
  div.search-tool-info-dialog {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.search-tool-citations-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-tool-citations-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
}

.search-tool-citations-container-indented {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding-left: 8px;
  margin-left: 32px;
  border-left: solid;
  border-width: 1px;
  border-color: var(--line-light);
}

.search-tool-citation {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.search-tool-citation-part {
  color: var(--text-light);
  font-size: 10pt;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.search-tool-chip-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.search-tool-chip-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.MuiChip-root.search-tool-chip {
  color: rgba(93, 146, 224, 0.9);
  background-color: rgba(93, 146, 224, 0.1);
  border-color: rgba(93, 146, 224, 0.3);
  border-width: 1px;
  border-style: solid;
  font-size: 10pt;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  div.MuiChip-root.search-tool-chip {
    max-width: 300px;
  }
}

.MuiButtonBase-root.MuiChip-root.search-tool-chip {
  color: rgba(93, 146, 224, 0.9);
  background-color: rgba(93, 146, 224, 0.1);
  border-color: rgba(93, 146, 224, 0.3);
  font-size: 10pt;
  font-weight: 400;
}

.MuiButtonBase-root.MuiChip-root.search-tool-chip:hover {
  background-color: rgba(93, 146, 224, 0.3);
}

.MuiButtonBase-root.MuiChip-root.search-tool-chip:focus {
  box-shadow: unset;
}

.MuiButtonBase-root.MuiChip-root .MuiChip-icon.search-tool-chip-icon {
  color: rgba(0, 0, 0, 0.20);
}

.search-tool-proceedings-container {
  width: 100%;
}

.search-tool-judgment-container {
  /* padding-top: 100px; */
}

.search-tool-judgment-item-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.search-tool-judgment-paragraph-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  background-color: white;
  color: var(--text-dark);
  font-size: 11.5pt;
  font-weight: 300;
  line-height: 1.8;
}

.search-tool-judgment-paragraph-container:hover {
  background-color: rgba(241, 242, 246, 1);
}

.search-tool-judgment-left-column-container  {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  min-width: 60px;
  font-size: 32px;
  font-weight: 600;
  color: var(--primary);
  padding-top: 1px;
  margin-right: 16px;
}

.search-tool-judgment-right-column-container {
  flex-grow: 1;
}

.search-tool-judgment-heading-1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 50px;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--primary);
  padding-top: 32px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.search-tool-judgment-heading-2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  min-width: 50px;
  font-size: 24px;
  font-weight: 600;
  color: var(--primary);
  padding-top: 6px;
  margin-right: 16px;
}

.search-tool-judgment-paragraph-tags-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 8px;
  padding-left: 16px;
  padding-bottom: 16px;
}

.search-tool-judgment-paragraph-tag-container {
  padding-right: 8px;
  padding-bottom: 8px;
}
